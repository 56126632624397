import { useState, useEffect, useRef } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon, CalendarDateRangeIcon } from "@heroicons/react/24/outline";

import Header from "../Components/Header";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../Components/Loading";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Timesheet() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  const [currentMonth, setCurrentMonth] = useState(dayjs().startOf("month"));
  const [currentYear, setCurrentYear] = useState(dayjs().startOf("year"));
  const [days, setDays] = useState([]);
  // Generate days array for the current month
  const generateDaysArray = (month) => {
    const startOfMonth = month.startOf("month");
    const endOfMonth = month.endOf("month");
    const daysInMonth = endOfMonth.date();

    // Previous month's days to fill the week
    const startOfWeek = startOfMonth.startOf("week");
    const endOfWeek = endOfMonth.endOf("week");

    // Days in calendar view
    const days = [];
    let currentDate = startOfWeek;

    while (currentDate.isBefore(endOfWeek) || currentDate.isSame(endOfWeek)) {
      days.push({
        date: currentDate.format("YYYY-MMM-DD"),
        display: currentDate.format("MMM DD, YYYY"),
        currMonth: currentDate.format("YYYY-MMM-DD").split("-")[1],
        currYear: currentDate.format("YYYY-MMM-DD").split("-")[0],
        checkCode: `${currentDate.format("YYYY-MMM-DD").split("-")[1]}-${
          currentDate.format("YYYY-MMM-DD").split("-")[0]
        }`,
        isCurrentMonth: currentDate.month() === month.month(),
        isToday: currentDate.isSame(dayjs(), "day"),
        events: [
          {
            id: 1,
            name: "Start time:",
            time: "",
          },
          {
            id: 2,
            name: "End time:",
            time: "",
          },
        ],
      });
      currentDate = currentDate.add(1, "day");
    }
    return days;
  };

  useEffect(() => {
    setIsVisible(true);
    var userData = localStorage.getItem("__BOTTechNetworkUser__");
    if (userData) {
      var userData1 = JSON.parse(userData);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/botTechNetwork/availabilityData`,
          {
            checkCode: `${currentMonth.format("MMM")}-${currentYear.format(
              "YYYY"
            )}`,
          },
          {
            headers: {
              authorization: userData1?.authToken, // Replace with your token
            },
          }
        )
        .then((response) => {
          if (!response?.data?.success) {
            setIsVisible(false);
            if (response?.data?.length > 0) {
              //setDays(generateDaysArray(currentMonth));
              setDays(response?.data[0]?.daysData);
            } else {
              setDays(generateDaysArray(currentMonth));
            }
          } else {
            setIsVisible(false);
            setDays(generateDaysArray(currentMonth));
          }
        })
        .catch((error) => {
          setIsVisible(false);
        });
    } else {
      navigate("/");
    }
  }, [currentMonth]);

  // Update the month state
  const goToPreviousMonth = () => {
    if (currentMonth.format("MMM") === "Jan") {
      setCurrentYear((prev) => prev.subtract(1, "year"));
    }
    setCurrentMonth((prev) => prev.subtract(1, "month"));
  };

  const goToNextMonth = () => {
    if (currentMonth.format("MMM") === "Dec") {
      setCurrentYear((prev) => prev.add(1, "year"));
    }
    setCurrentMonth((prev) => prev.add(1, "month"));
  };

  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [thisDay, setThisDay] = useState({});

  const [copyAvail, setCopyAvail] = useState("only-today");
  return (
    <>
      <Header />
      {isVisible && <Loading />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-indigo-100">
                  <CalendarDateRangeIcon
                    aria-hidden="true"
                    className="size-6 text-gray-500"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold text-gray-900"
                  >
                    Set timings for&nbsp;
                    <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                      {selectedDate}
                    </span>
                  </DialogTitle>
                  <div className="mt-2">
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm/6 font-medium text-gray-900"
                        >
                          Start Time
                        </label>
                        <div className="mt-2">
                          <input
                            name="start-time"
                            type="time"
                            value={startTime}
                            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                            onChange={(e) => {
                              setStartTime(e?.target?.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm/6 font-medium text-gray-900"
                        >
                          End Time
                        </label>
                        <div className="mt-2">
                          <input
                            name="end-time"
                            type="time"
                            value={endTime}
                            onChange={(e) => {
                              setEndTime(e?.target?.value);
                            }}
                            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <fieldset className="mt-4">
                <legend className="text-sm/6 font-semibold text-gray-900">
                  Copy Availability
                </legend>
                <p className="mt-1 text-sm/6 text-gray-600">
                  Please select from following to copy the availability to other
                  days.
                </p>
                <div className="mt-6 space-y-6">
                  <div className="flex items-center gap-x-3">
                    <input
                      checked={copyAvail == "only-today" ? true : false}
                      id="only-today"
                      name="copy-availability"
                      type="radio"
                      onClick={() => {
                        setCopyAvail("only-today");
                      }}
                      className="relative cursor-pointer size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                    />
                    <label
                      htmlFor="only-today"
                      className="block text-sm/6 font-medium text-gray-900 cursor-pointer"
                      onClick={() => {
                        setCopyAvail("only-today");
                      }}
                    >
                      Only for selected date.
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <input
                      checked={copyAvail == "next-5" ? true : false}
                      onClick={() => {
                        setCopyAvail("next-5");
                      }}
                      id="next-5"
                      name="copy-availability"
                      type="radio"
                      className="relative cursor-pointer size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                    />
                    <label
                      htmlFor="next-5"
                      className="block text-sm/6 font-medium text-gray-900 cursor-pointer"
                      onClick={() => {
                        setCopyAvail("next-5");
                      }}
                    >
                      Next 5 days.
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <input
                      checked={copyAvail == "whole-month" ? true : false}
                      id="whole-month"
                      onClick={() => {
                        setCopyAvail("whole-month");
                      }}
                      name="copy-availability"
                      type="radio"
                      className="relative size-4 cursor-pointer appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                    />
                    <label
                      htmlFor="whole-month"
                      className="block text-sm/6 font-medium text-gray-900 cursor-pointer"
                      onClick={() => {
                        setCopyAvail("whole-month");
                      }}
                    >
                      For whole month.
                    </label>
                  </div>
                </div>
              </fieldset>
              <div className="mt-5 sm:mt-6 grid gap-x-6 sm:grid-cols-2">
                <button
                  type="button"
                  onClick={() => {
                    for (var i = 0; i < days.length; i++) {
                      if (days[i].display === selectedDate) {
                        days[i].events[0].time = "";
                        days[i].events[1].time = "";
                      }
                    }

                    setIsVisible(true);
                    var userData = localStorage.getItem(
                      "__BOTTechNetworkUser__"
                    );
                    if (userData) {
                      var userData1 = JSON.parse(userData);
                      axios
                        .put(
                          `${process.env.REACT_APP_BASE_URL}/botTechNetwork/availabilityData`,
                          {
                            checkCode: `${thisDay?.checkCode}`,
                            daysData: days,
                          },
                          {
                            headers: {
                              authorization: userData1?.authToken, // Replace with your token
                            },
                          }
                        )
                        .then((response) => {
                          if (response?.data?.success) {
                            setIsVisible(false);
                          } else {
                            setIsVisible(false);
                          }
                        })
                        .catch((error) => {
                          setIsVisible(false);
                        });
                    } else {
                      navigate("/");
                    }

                    setOpen(false);
                  }}
                  className="w-full mb-2 justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-clear-600"
                >
                  Clear
                </button>
                <button
                  type="button"
                  onClick={() => {
                    console.log(copyAvail);
                    for (var i = 0; i < days.length; i++) {
                      if (
                        copyAvail === "only-today" &&
                        days[i].display === selectedDate
                      ) {
                        days[i].events[0].time = startTime;
                        days[i].events[1].time = endTime;
                      }
                      if (
                        copyAvail === "next-5" &&
                        days[i].display === selectedDate
                      ) {
                        days[i].events[0].time = startTime;
                        days[i].events[1].time = endTime;
                        for (var k = 1; k <= 5; k++) {
                          if (days[i + k]?.events != undefined) {
                            days[i + k].events[0].time = startTime;
                            days[i + k].events[1].time = endTime;
                          }
                        }
                      }
                      if (copyAvail === "whole-month") {
                        days[i].events[0].time = startTime;
                        days[i].events[1].time = endTime;
                      }
                    }

                    setDays(days);

                    setIsVisible(true);
                    var userData = localStorage.getItem(
                      "__BOTTechNetworkUser__"
                    );
                    if (userData) {
                      var userData1 = JSON.parse(userData);
                      axios
                        .put(
                          `${process.env.REACT_APP_BASE_URL}/botTechNetwork/availabilityData`,
                          {
                            checkCode: `${thisDay?.checkCode}`,
                            daysData: days,
                          },
                          {
                            headers: {
                              authorization: userData1?.authToken, // Replace with your token
                            },
                          }
                        )
                        .then((response) => {
                          if (response?.data?.success) {
                            setIsVisible(false);
                          } else {
                            setIsVisible(false);
                          }
                        })
                        .catch((error) => {
                          setIsVisible(false);
                        });
                    } else {
                      navigate("/");
                    }
                    setOpen(false);
                  }}
                  className="inline-flex mb-2 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Submit
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      <div className="lg:flex lg:h-full lg:flex-col pt-16">
        <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
          <h1 className="text-base font-semibold text-gray-900">
            <time dateTime="2022-01">Set Availability</time>
          </h1>
          <div className="flex items-center">
            <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
              <button
                type="button"
                onClick={goToPreviousMonth}
                className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="size-5" aria-hidden="true" />
              </button>

              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                onClick={goToNextMonth}
                className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="size-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </header>
        <div className="shadow ring-1 ring-black/5 lg:flex lg:flex-auto lg:flex-col">
          <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs/6 font-semibold text-gray-700 lg:flex-none">
            <div className="bg-white py-2">
              S<span className="sr-only sm:not-sr-only">un</span>
            </div>
            <div className="bg-white py-2">
              M<span className="sr-only sm:not-sr-only">on</span>
            </div>
            <div className="bg-white py-2">
              T<span className="sr-only sm:not-sr-only">ue</span>
            </div>
            <div className="bg-white py-2">
              W<span className="sr-only sm:not-sr-only">ed</span>
            </div>
            <div className="bg-white py-2">
              T<span className="sr-only sm:not-sr-only">hu</span>
            </div>
            <div className="bg-white py-2">
              F<span className="sr-only sm:not-sr-only">ri</span>
            </div>
            <div className="bg-white py-2">
              S<span className="sr-only sm:not-sr-only">at</span>
            </div>
          </div>
          <div className="flex bg-gray-200 text-xs/6 text-gray-700 lg:flex-auto">
            <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-5 lg:gap-px">
              {days.map((day) => (
                <div
                  key={day.date}
                  onClick={() => {
                    if (day.isCurrentMonth) {
                      setOpen(true);
                      setSelectedDate(day?.display);
                      setThisDay(day);
                      setStartTime(day.events[0].time);
                      setEndTime(day.events[1].time);
                      setCopyAvail("only-today");
                    }
                  }}
                  className={classNames(
                    day.isCurrentMonth
                      ? "bg-white cursor-pointer "
                      : "bg-gray-50 text-gray-500 cursor-not-allowed",
                    "relative px-3 py-2 hover:bg-gray-100",
                    day.isToday ? "border border-2 border-indigo-600" : ""
                  )}
                >
                  <time dateTime={day?.date}>{day?.display}</time>
                  {day.events.length > 0 && day.isCurrentMonth && (
                    <ol className="mt-2">
                      {day.events.slice(0, 2).map((event) => (
                        <li
                          key={event.id}
                          className="items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-green-600/20 ring-inset mb-2"
                        >
                          <a href={event.href} className="group flex">
                            <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                              {event.name}
                            </p>
                            <time
                              dateTime={event.datetime}
                              className="ml-3 flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                            >
                              {event?.time?.split(":")[0] > 12
                                ? `${Number(event?.time?.split(":")[0]) - 12}:${
                                    event?.time?.split(":")[1]
                                  } PM`
                                : event?.time != ""
                                ? `${event?.time} AM`
                                : ""}
                            </time>
                          </a>
                        </li>
                      ))}
                    </ol>
                  )}
                </div>
              ))}
            </div>
            <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
              {days.map((day) => (
                <button
                  key={day.date}
                  type="button"
                  onClick={() => {
                    setOpen(true);
                    setSelectedDate(day?.display);
                    setThisDay(day);
                    setStartTime(day.events[0].time);
                    setEndTime(day.events[1].time);
                  }}
                  className={classNames(
                    day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                    (day.isSelected || day.isToday) && "font-semibold",
                    day.isSelected && "text-white",
                    !day.isSelected && day.isToday && "text-indigo-600",
                    !day.isSelected &&
                      day.isCurrentMonth &&
                      !day.isToday &&
                      "text-gray-900",
                    !day.isSelected &&
                      !day.isCurrentMonth &&
                      !day.isToday &&
                      "text-gray-500",
                    "flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10"
                  )}
                >
                  <time
                    dateTime={day.date}
                    className={classNames(
                      day.isSelected &&
                        "flex size-6 items-center justify-center rounded-full",
                      day.isSelected && day.isToday && "bg-indigo-600",
                      day.isSelected && !day.isToday && "bg-gray-900",
                      "ml-auto"
                    )}
                  >
                    {day.date.split("-").pop().replace(/^0/, "")}
                  </time>
                  {day.events.length > 0 && (
                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                      {day.events.map((event) => (
                        <span
                          key={event.id}
                          className="mx-0.5 mb-1 size-1.5 rounded-full bg-gray-400"
                        />
                      ))}
                    </span>
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
