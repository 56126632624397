import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

export default function Main() {
  return (
    <>
      <Disclosure as="nav" className="bg-white shadow py-4">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              {/* Mobile menu button */}
              <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                <Bars3Icon
                  aria-hidden="true"
                  className="block size-6 group-data-[open]:hidden"
                />
                <XMarkIcon
                  aria-hidden="true"
                  className="hidden size-6 group-data-[open]:block"
                />
              </DisclosureButton>
            </div>
            <div className="flex flex-1 items-center space-between sm:items-stretch justify-center sm:justify-between">
              <div className="flex shrink-0 items-center">
                <img
                  alt="Eurotex Tech Help Network"
                  src="https://images.shirtly.com/image/upload/v1739212161/TechHelpNet/Vertical-Name_awgtci.png"
                  className="h-16 mx-auto block"
                />
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                <a
                  href="/login"
                  className="inline-flex items-center hover:border-b-2 hover:border-indigo-500 px-1 pt-1 text-md font-medium text-gray-900"
                >
                  Customer Login
                </a>
                <a
                  href="/login"
                  className="inline-flex items-center hover:border-b-2 hover:border-indigo-500 px-1 pt-1 text-md font-medium text-gray-900"
                >
                  Technician Portal
                </a>
              </div>
            </div>
          </div>
        </div>

        <DisclosurePanel className="sm:hidden">
          <div className="space-y-1 pb-4 pt-2">
            {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
            <DisclosureButton
              as="a"
              href="/login"
              className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
            >
              Customer Login
            </DisclosureButton>
            <DisclosureButton
              as="a"
              href="/login"
              className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
            >
              Technician Portal
            </DisclosureButton>
          </div>
        </DisclosurePanel>
      </Disclosure>
      <div
        style={{
          background: `url(
            'https://images.shirtly.com/image/upload/v1740405710/TechHelpNet/TECHHELPNET-Banner-BOT_xoohuo.png'
          )`,
          backgroundSize: "cover", // Ensures the image covers the entire div
          backgroundPosition: "center center", // Centers the image
          height: "50vh", // Set a height for visibility
          width: "100%", // Full width
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="bg-[#002f5e] px-6 py-8 lg:px-8">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center">
          <h2 className="text-2xl font-semibold tracking-tight text-white sm:text-5xl">
            About Us
          </h2>
          <p className="mt-4 text-pretty text-md sm:text-xl font-medium text-white text-left">
            Welcome to Eurotex, your trusted partner in providing expert
            technician support for a wide range of print-on-demand machines. We
            specialize in maintaining, troubleshooting, and optimizing equipment
            such as DTG printers, DTF machines, sublimation printers, heat
            presses, embroidery machines, and more. Our team of certified
            technicians is dedicated to ensuring your machines operate at peak
            performance, minimizing downtime and maximizing productivity. With
            years of industry experience, we offer on-site support, remote
            diagnostics, and preventive maintenance plans tailored to meet the
            unique needs of your business. We understand the critical role that
            reliable equipment plays in the print-on-demand industry, and we’re
            committed to delivering fast, efficient, and cost-effective
            solutions. At Eurotex, we don’t just fix machines—we help businesses
            thrive. Our goal is to empower your operation with technical
            excellence, exceptional customer service, and the latest industry
            knowledge. Partner with us to keep your production running smoothly
            and your business growing.
          </p>
        </div>
      </div>
      <div className="bg-white py-8">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-2xl font-semibold tracking-tight text-indigo-600 text-center sm:text-5xl">
            What we service
          </h2>
          <p className="mt-4 text-pretty text-md sm:text-xl font-medium text-gray-600 text-left">
            We specialize in servicing all types of decoration machines,
            including DTG printers, DTF machines, embroidery machines, and heat
            presses. Our expert technicians provide comprehensive support, from
            troubleshooting and repairs to routine maintenance, ensuring your
            equipment runs at peak performance. No matter the brand or model,
            we’ve got you covered to keep your production seamless and
            efficient.
          </p>
          <div className="mt-20 flow-root">
            <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
              <div className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                <h3 className="text-lg font-semibold text-gray-900">
                  Embroidery Machines
                </h3>

                <ul
                  role="list"
                  className="mt-6 space-y-3 text-lg text-gray-600"
                >
                  <li className="flex gap-x-3 items-center">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-8 w-5 flex-none text-indigo-600 text-lg"
                    />
                    Tajima
                  </li>
                  <li className="flex gap-x-3 items-center">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-8 w-5 flex-none text-indigo-600 text-lg"
                    />
                    Barudan
                  </li>
                  <li className="flex gap-x-3 items-center">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-8 w-5 flex-none text-indigo-600 text-lg"
                    />
                    ZSK
                  </li>
                  <li className="flex gap-x-3 items-center">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-8 w-5 flex-none text-indigo-600 text-lg"
                    />
                    Fortever
                  </li>
                  <li className="flex gap-x-3 items-center">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-8 w-5 flex-none text-indigo-600 text-lg"
                    />
                    BAI
                  </li>
                  <li className="flex gap-x-3 items-center">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-8 w-5 flex-none text-indigo-600 text-lg"
                    />
                    Chinese Generic
                  </li>
                  <li className="flex gap-x-3 items-center">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-8 w-5 flex-none text-indigo-600 text-lg"
                    />
                    Happy
                  </li>
                </ul>
              </div>
              <div className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                <h3 className="text-lg font-semibold text-gray-900">
                  DTF Machines
                </h3>

                <ul
                  role="list"
                  className="mt-6 space-y-3 text-lg text-gray-600"
                >
                  <li className="flex gap-x-3 items-center">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-8 w-5 flex-none text-indigo-600 text-lg"
                    />
                    All brands
                  </li>
                </ul>
              </div>
              <div className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                <h3 className="text-lg font-semibold text-gray-900">
                  Heat Press Machines
                </h3>

                <ul
                  role="list"
                  className="mt-6 space-y-3 text-lg text-gray-600"
                >
                  <li className="flex gap-x-3 items-center">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-8 w-5 flex-none text-indigo-600 text-lg"
                    />
                    All brands
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#002f5e] px-6 py-8 lg:px-8">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center">
          <p className="mt-4 text-pretty text-md sm:text-xl font-medium text-white">
            We deliver comprehensive service and support for a wide range of DTF
            equipment, Embroidery machines and more. With a nationwide network
            of skilled technicians, we provide unmatched reliability and
            expertise that distinguish us from the competition.
          </p>
          <a
            type="button"
            href="/login"
            className="bg-[#79a8ce] py-4 px-2 cursor-pointer block mx-auto sm:w-[35%] text-indigo-600 text-md md:text-4xl font-bold rounded-lg mt-8 mb-4 shadow-2xl"
          >
            REQUEST SERVICE
          </a>
        </div>
      </div>
      <footer className="bg-[#0a153c]">
        <div className="mx-auto px-6 py-4 lg:px-8">
          <nav
            aria-label="Footer"
            className="-mb-6 flex flex-wrap justify-center gap-x-12 gap-y-3"
          >
            <a
              href="/login"
              className="inline-flex items-center px-1 pt-1 text-2xl font-bold text-white"
            >
              Customer Login
            </a>
            <a
              href="/login"
              className="inline-flex items-center px-1 pt-1 text-2xl font-bold text-white"
            >
              Technician Portal
            </a>
          </nav>

          <p className="mt-10 text-center text-sm/6 text-white">
            &copy; 2025 Eurotex North America, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}
