"use client";
import { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../Components/Loading";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

import { PrinterIcon } from "@heroicons/react/20/solid";

export default function Customers() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  useEffect(() => {
    setIsVisible(true);
    var userData = localStorage.getItem("__BOTTechNetworkUser__");
    if (userData) {
      var userData1 = JSON.parse(userData);
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      setUserDetails({
        ...userData1,
        password: CryptoJS.AES.decrypt(userData1.password, secretKey).toString(
          CryptoJS.enc.Utf8
        ),
      });

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/botTechNetwork/customerData`,
          {},
          {
            headers: {
              authorization: userData1?.authToken, // Replace with your token
            },
          }
        )
        .then((response) => {
          if (response?.data?.allRequests) {
            setIsVisible(false);
            setDashboardData(response?.data);
          } else {
            setIsVisible(false);
          }
        })
        .catch((error) => {
          setIsVisible(false);
        });
    } else {
      navigate("/");
    }
  }, []);
  const updateCustomerStatus = (data) => {
    setIsVisible(true);
    var dataToSend = {
      userId: data?.userId,
      active: data?.active == "false" ? false : true,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/botTechNetwork/admin/updateCustomerStatus`,
        dataToSend,
        {
          headers: {
            authorization: userDetails?.authToken, // Replace with your token
          },
        }
      )
      .then((response) => {
        if (response?.data?.success) {
          setIsVisible(false);
          toast.success("Success!! User Status updated.");
        } else {
          setIsVisible(false);
          toast.warn("Error!! Please try again later.");
        }
      })
      .catch((error) => {
        setIsVisible(false);
        toast.success("Error!! Please try again later.");
      });
  };
  return (
    <>
      <Header />
      {isVisible && <Loading />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <main className="bg-white-100">
        <div className="relative isolate pt-16">
          {/* Secondary navigation */}
          <header className="pb-4 pt-6 sm:pb-6">
            <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
              <h1 className="text-base/7 font-semibold text-gray-900">
                Customers
              </h1>
            </div>
          </header>

          {/* Stats */}
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  All Customers
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.allRequests?.totalCount || "--"}
                </dd>
              </div>

              <div className="sm:border-l lg:border-l flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  Active Customers
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.completedRequest?.totalCount || "--"}
                </dd>
              </div>
              <div className="sm:border-l lg:border-l flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  In-active Customers
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.cancelledRequests?.totalCount || "--"}
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="mt-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-7xl lg:mx-0 lg:max-w-none">
              <div
                style={{
                  height: "70vh",
                }}
              >
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold text-gray-900">
                      Registered Customers
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                      A list of all the customers signed-up.
                    </p>
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className=" shadow ring-1 ring-black/5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Signed-up on
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Details
                              </th>

                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Company Details
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {dashboardData?.allRequests?.data?.map((person) => (
                              <tr key={person?.userId}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {dayjs
                                    .unix(person?.createdDate)
                                    .format("MMM DD, YYYY")}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {person?.name}
                                  <br></br>
                                  {person?.email}
                                  <br></br>
                                  {person?.contact}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {person?.companyName}
                                  <br></br>
                                  <a
                                    href={person?.website}
                                    target="_blank"
                                    className="underline"
                                  >
                                    {person?.website}
                                  </a>
                                  <br></br>
                                  {person?.address}
                                  <br></br>
                                  {person?.about}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <span
                                    className={`inline-flex items-center gap-x-1.5 rounded-md bg-${
                                      person?.active ? "green" : "red"
                                    }-100 px-1.5 py-0.5 text-xs font-medium text-${
                                      person?.active ? "green" : "red"
                                    }-800`}
                                  >
                                    <svg
                                      viewBox="0 0 6 6"
                                      aria-hidden="true"
                                      className={`size-1.5 fill-${
                                        person?.active ? "green" : "red"
                                      }-500`}
                                    >
                                      <circle r={3} cx={3} cy={3} />
                                    </svg>
                                    {person?.active ? "Active" : "In-active"}
                                  </span>
                                </td>
                                <td>
                                  <select
                                    id="status"
                                    name="status"
                                    value={person?.active}
                                    onChange={(e) => {
                                      var updatedItems =
                                        dashboardData?.allRequests?.data?.map(
                                          (item) => {
                                            if (
                                              item.userId === person?.userId
                                            ) {
                                              return {
                                                ...item,
                                                active:
                                                  e?.target?.value == "false"
                                                    ? false
                                                    : true,
                                              }; // Update the name
                                            }
                                            return item;
                                          }
                                        );
                                      setDashboardData({
                                        ...dashboardData,
                                        allRequests: {
                                          ...dashboardData?.allRequests,
                                          data: updatedItems,
                                        },
                                      });
                                      var updateUserData = updatedItems?.filter(
                                        (item) =>
                                          item?.userId === person?.userId
                                      );
                                      if (e?.target?.value !== "") {
                                        updateCustomerStatus(updateUserData[0]);
                                      }
                                    }}
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                                  >
                                    <option value={true}>Active</option>
                                    <option value={false}>In-active</option>
                                  </select>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
