import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./Pages/Main";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Dashboard from "./Pages/Dashboard";
import Timesheet from "./Pages/Timesheet";
import Techs from "./Pages/Techs";
import Customers from "./Pages/Customers";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/techs" element={<Techs />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/timesheet" element={<Timesheet />} />
      </Routes>
    </Router>
  );
}

export default App;
